<script>
  import Logo from '../Logo.svelte'
  import Button from './Button.svelte'
  import NavItemMobile from './NavItemMobile.svelte'

  import DropdownContext from './ProfileButton.svelte'

  import { navState } from './menu-store.js'
  import { getContext } from 'svelte'
  import { brandContextKey } from './context.js'

  export let menuItems
  export let profile

  const { config } = getContext(brandContextKey)

  function toggleMobileMenu () {
    navState.update(n => !n)
  }
</script>

<div class="mobile-menu">
  <div class="mobile-menu-header">
    <div class="brand">
      <Logo href="{config.applicationUrl}" inverse />
    </div>
    <div class="controls">
      {#if !profile}
      <Button skin="tertiary" href="{config.authUrl}/signup" rel="external">Try for free</Button>
      {/if}
      <Button layout="icon-only" iconSize="24" skin="borderless-i" on:click={toggleMobileMenu} icon="close">Close menu</Button>
    </div>
  </div>
  <div class="mobile-menu-content">
    <nav class="narrow-nav">
      {#each menuItems as menuItem}
        <NavItemMobile href={menuItem.url}>{menuItem.label}</NavItemMobile>
      {/each}
    </nav>
  </div>
  <div class="mobile-menu-footer">
    {#if profile}

    <DropdownContext {config} {profile} touch={true} inverted/>

    {:else}
      <Button block skin="inverted" href="https://beyonk.is/available" rel="external">Book a demo</Button>
      <p>Already have an account? <a href={new URL('/login', config.authUrl).toString()} rel="external">Log in.</a></p>
    {/if}
  </div>
</div>

<style>
  .mobile-menu {
    background: var(--colour-brand-darker-green);
    color: #FFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4000;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1000px) {
    .mobile-menu-header {
      height: 7.5rem;
    }
  }

  .mobile-menu-header {
    display: flex;
    height: 6rem;
    padding: 0 2.4rem;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-menu-header :global(button:last-of-type) {
    margin-right: -1.4rem;
  }

  .mobile-menu-content {
    padding: 2.4rem;
    flex-grow: 1;
  }

  .mobile-menu-footer {
    padding: 2.4rem;
  }

  .mobile-menu-footer > :global(* + *) {
    margin-top: 1.4rem;
  }

  .mobile-menu-footer p {
    text-align: center;
  }

  .mobile-menu-footer p a {
    color: #FFF;
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
</style>
