<script>
  export let href
  export let rel

  import { navState } from './menu-store.js'

  function openLink () {
    navState.set(0)
  }

</script>

<a {href} {rel} on:click={openLink}><slot /></a>

<style>
  a {
    color: #FFF;
    font-size: 1.6rem;
    text-decoration: none;
    display: block;
    margin-bottom: 24px;
    line-height: 1.6;
    font-weight: 500;
  }

  a:hover {
    color: rgba(255,255,255,0.8);
  }
</style>