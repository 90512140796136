<script>
  // Simplified version of SystemIcon for use in Header
  export let icon
  export let fillColor = '#7B7B7B'
  export let width = 24
  export let halfWidth = 12
  export let height = 24

  let html

  switch (icon) {
    case 'menu':
      html = `<svg width=${width} height=${height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z" fill=${fillColor}></svg>`
      break
    case 'close':
      html = `<svg width=${width} height=${height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z" fill=${fillColor}></svg>`
      break
    case 'caret-down':
      html = `<svg width=${halfWidth} height=${height} viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 10L6 16L1 10H11Z" fill=${fillColor} /></svg>`
      break
    case 'caret-up':
      html = `<svg width=${halfWidth} height=${height} viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 14L6 8L11 14L1 14Z" fill=${fillColor} /></svg>`
      break
    default:
      console.log('undefined icon')
  }
</script>

{@html html}





