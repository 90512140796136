function getHue (hash) {
  const hue = [ ...hash ].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0) % 360

  return Math.abs(hue)
}

function hslForHash (inputString) {
  return { h: getHue(inputString), s: 60 }
}

export {
  hslForHash
}
