import { tick } from 'svelte'
import { writable } from 'svelte/store'
import { ResizableElementDispatcher } from '@beyonk/embedded'

const attributionHeight = 40
const headerSize = writable({ height: '91px', computed: 91 })
const pageSize = writable()

function watchForPageSizeChanges (w) {
  const reo = new ResizableElementDispatcher(w, 'beyonk:portal:resize')
  return pageSize.subscribe(s => {
    reo.update(s)
  })
}

async function firePageSizeUpdate () {
  await tick()
  const scrollHeight = document.getElementById('page-content').scrollHeight
  pageSize.set(`${scrollHeight + attributionHeight}px`)
}

export {
  headerSize,
  watchForPageSizeChanges,
  firePageSizeUpdate
}
