<a class:byk-is-dark={dark} href={applicationUrl} target="_blank" rel="noopener noreferrer">
  <Logomark />
  {text}
  {#if secure}
    <LockIcon fillColor="currentColor" />
  {/if}
</a>

<style>
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 12px;
    color: var(--byk-c-grey);
    text-decoration: none;
    white-space: nowrap;
  }

  a.byk-is-dark {
    color: var(--byk-c-grey-dark);
  }

  a :global(svg) {
    width: auto;
    height: 12px;
  }
</style>

<script>
  import { LockIcon } from '@beyonk/icons'
  import { Logomark } from '..'

  export let applicationUrl
  export let dark = false
  export let secure = true
  export let text = 'Powered by Beyonk. Secure booking'
</script>
