import * as sapper from '@sapper/app'
import Api from '@beyonk/sapper-httpclient'
import 'intersection-observer'
import 'regenerator-runtime/runtime'
import * as Sentry from './sentry.js'
import { URLs } from '~util/dynamic-environment.js'

Api.configure({
  baseUrl: new URL('/api/v1', URLs.api).toString()
})

if (process.env.ENVIRONMENT_NAME === 'prod') {
  window.onunhandledrejection = function (e) {
    console.warn('Unhandled Rejection', e.reason)
  }
  window.onerror = function (message) {
    console.warn('Unhandled Error', message)
  }
}

async function bootstrap () {
  Sentry.init()
  sapper.start({
    target: document.querySelector('#sapper')
  })
}

bootstrap()
