<script>
  import { getCdnUrl } from '@beyonk/content-delivery'
  import { hslForHash } from './id-colour.js'

  export let config
  export let profile
  export let size = 'md'

  $: names = [ profile.firstName, profile.lastName, profile.companyName ].filter(Boolean)
  $: initials = names ? names.join(' ').split(' ').map(n => n[0]).join('') : '?'
  $: displayName = names ? names.join(' ') : 'Beyonk User'
  $: colour = hslForHash(initials)
  $: image = profile.photo || profile.brand.logo
</script>

<div class="avatar" data-initials={initials} data-size={size} style="background-color: hsl({colour.h}, {colour.s}%, 50%);">
  {#if Object.keys(image).length > 0}
    <img
      alt="Profile image for {displayName}"
      src={getCdnUrl(config.cdnBaseUrl, config.environmentName, image, 'tiny')} />
  {/if}
</div>

<style>
  .avatar {
    --avatar-r: 20px;

    width: calc(var(--avatar-r) * 2);
    height: calc(var(--avatar-r) * 2);
    border-radius: 50%;
    overflow: hidden;
    font-size: 16px;
  }

  .avatar[data-size="xs"] {
    --avatar-r: 14px;

    font-size: 12px;
  }

  .avatar[data-size="lg"] {
    --avatar-r: 60px;

    font-size: 48px;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .avatar:empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar:empty::before {
    content: attr(data-initials);
    color: white;
  }
</style>