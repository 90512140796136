function ogUrl (page, applicationUrl) {
  const baseUrl = new URL(applicationUrl)
  const url = new URL(page.path, baseUrl)

  for (const [ key, value ] of Object.entries(page.query)) {
    url.searchParams.append(key, value)
  }

  return url.toString()
}

export {
  ogUrl
}
