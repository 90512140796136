{#if brand.header}
<iframe bind:this={header} title="{brand.name} Header" scrolling="no" src={brand.header}></iframe>
{:else}
<div class="brand-header">
  <a href={brand.url} rel="external">
    <img src="{getCdnUrl(process.env.CDN_BASE_URL, process.env.NODE_ENV, brand.logo, 'whitelabel-logo')}" alt="{brand.name}" />
  </a>
</div>
{/if}

<script>
  import { onMount } from 'svelte'
  import { getCdnUrl } from '@beyonk/content-delivery'
  import { ResizableElementObserver } from '@beyonk/embedded'
  import { headerSize } from '~util/resizable-elements/resizable-elements.js'
  
  export let brand
  let header

  onMount(() => {
    if (!brand.header) { return }

    const reo = new ResizableElementObserver(window)
    reo.subscribe(height => {
      header.style.height = height
      header.style.minHeight = height
      header.style.maxHeight = height
      headerSize.set({
        height,
        computed: window.getComputedStyle(header).height.replace(/px$/, '')
      })
    })

    return () => {
      reo.unsubscribe()
    }
  })
</script>

<style lang="scss">
  .brand-header {
    padding: 7px 15px 0 10px;
  }

  a {
    display: block;
    max-height: var(--wl-header-height);
    height: var(--wl-header-height);
  }

  img {
    height: 100%;
  }
</style>