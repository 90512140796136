<script>
  import ButtonInnerLayout from './ButtonInnerLayout.svelte'

  export let href
  export let rel
  export let layout
  export let icon
  export let iconSize
  export let block
  export let disabled
  export let skin = 'secondary'
  export let full = false

</script>

{#if href}
  <a class="c-button"
    on:click
    {href}
    {rel}
    {disabled}
    class:c-button--primary={skin === 'primary'}
    class:c-button--inverted={skin === 'inverted'}
    class:c-button--link={skin === 'link'}
    class:c-button--borderless={skin === 'borderless'}
    class:c-button--borderless-i={skin === 'borderless-i'}
    class:c-button--secondary={skin === 'secondary'}
    class:c-button--tertiary={skin === 'tertiary'}
    class:c-button--block={block === true}
    class:c-button--icon={layout === 'icon-only'}
    class:c-button--full={full}
  >
    <ButtonInnerLayout {icon} {iconSize} {layout}>
      <slot />
    </ButtonInnerLayout>
  </a>
{:else}
  <button class="c-button"
    on:click
    {disabled}
    class:c-button--primary={skin === 'primary'}
    class:c-button--inverted={skin === 'inverted'}
    class:c-button--link={skin === 'link'}
    class:c-button--borderless={skin === 'borderless'}
    class:c-button--borderless-i={skin === 'borderless-i'}
    class:c-button--secondary={skin === 'secondary'}
    class:c-button--tertiary={skin === 'tertiary'}
    class:c-button--block={block === true}
    class:c-button--icon={layout === 'icon-only'}
    class:c-button--full={full}
  >
    <ButtonInnerLayout {icon} {iconSize} {layout}>
      <slot />
    </ButtonInnerLayout>
  </button>
{/if}

<style>
  .c-button {
    appearance: none;
    font-family: var(--font-base);
    padding: 1.3rem 1rem;
    line-height: 1.1;
    font-size: 1.6rem;
    border-radius: .3rem;
    font-weight: 600;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
  }

  .c-button--primary {
    background: var(--colour-brand-green);
    border: .1rem solid var(--colour-brand-green);
    color: #FFF;
  }

  .c-button--primary:hover,
  .c-button--primary:active,
  .c-button--primary:focus {
    background: #007F6F;
    border-color: #007F6F;
    transition: background 0.18s ease-in-out, border 0.18s ease-in-out;
  }

  :global(.c-button--primary svg *) {
    fill: #FFF;
  }

  .c-button--inverted {
    border: .1rem solid white;
    background-color: transparent;
    color: white;

  }

  .c-button--inverted:hover,
  .c-button--inverted:active,
  .c-button--inverted:focus {
    background-color: white;
    color: var(--colour-brand-darkest-green);
  }

  :global(.c-button--inverted svg *) {
    fill: #FFF;
  }


  .c-button--secondary {
    background: transparent;
    border: .1rem solid var(--colour-brand-darker-green);
    color: var(--colour-brand-darker-green);
  }

  .c-button--secondary:hover,
  .c-button--secondary:active,
  .c-button--secondary:focus {
    background: rgba(20,69,64,.05);
    color: #007F6F;
    border-color: #007F6F;
    transition: background 0.18s ease-in-out, color 0.18s ease-in-out, border-color 0.18s ease-in-out;
  }

  :global(.c-button--secondary svg *) {
    fill: var(--colour-brand-darker-green);
  }

  .c-button--tertiary {
    background: var(--colour-orange-600);
    color: #FFF;
  }

  .c-button--tertiary:hover,
  .c-button--tertiary:active,
  .c-button--tertiary:focus {
    background: rgb(194, 68, 23);
    transition: background 0.18s ease-in-out, border 0.18s ease-in-out;
  }

  .c-button--link {
    background: transparent;
    border: .1rem solid transparent;
    text-decoration: underline;
    padding-left: .2rem;
    padding-right: .2rem;
    color: var(--colour-semantic-link);
  }

  :global(.c-button--link svg *) {
    fill: var(--colour-semantic-link);
  }

  .c-button--full {
    flex: 1;
    justify-content: center;
    padding: 1rem;
  }

  .c-button--borderless {
    background: transparent;
    border: .1rem solid transparent;
    color: var(--colour-brand-darker-green);
  }

  :global(.c-button--borderless svg *) {
    fill: var(--colour-brand-darker-green);
  }

  .c-button--borderless-i {
    background: transparent;
    border: .1rem solid transparent;
    color: #FFF;
  }

  :global(.c-button--borderless-i svg *) {
    fill: #FFF;
  }

  .c-button--block {
    width: 100%;
    display: block;
    text-align: center;
  }

  .c-button--icon {
    padding: 0;
    line-height: 1;
    display: block;
    width: 5.2rem;
    height: 5.2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: none;
  }
</style>
