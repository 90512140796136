import * as constants from '@beyonk/constants'

const activityIds = Object.keys(constants.categories)

const categoriesToNames = Object
  .values(constants.categories)
  .reduce((cats, { searchable, category, name }) => {
    if (searchable) {
      cats[category] = name
    }
    return cats
  }, {})

const dropdownOptions = Object
  .values(constants.categories)
  .reduce((cats, { searchable, category, name }) => {
    if (searchable) {
      cats[category] = { name }
    }
    return cats
  }, {})

export {
  activityIds,
  dropdownOptions,
  categoriesToNames
}
