<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   id="svg8"
   xml:space="preserve"
   style="enable-background:new 0 0 263.2 127;"
   viewBox="0 0 263.2 127"
   y="0px"
   x="0px"
   version="1.1">
	<defs id="defs12" />
  <g>
		<path class="border" d="M 234.8,98.5 H 28.5 v -70 h 206.4 v 70 z m 28.4,-70 V 22.6 H 240.7 V 0 h -5.9 V 22.5 H 28.5 V 0 H 22.6 V 22.5 H 0 v 5.9 H 22.5 V 98.5 H 0 v 5.9 H 22.5 V 127 h 5.9 V 104.5 H 234.8 V 127 h 5.9 v -22.5 h 22.5 V 98.6 H 240.7 V 28.5 Z" />
		<path class="letter b" d="m 56.8,70.2 h 1.9 c 1.1,0.1 2.2,-0.1 3.2,-0.6 1,-0.7 1.3,-2.1 0.5,-3.1 -0.1,-0.2 -0.3,-0.4 -0.5,-0.5 -1,-0.5 -2.1,-0.6 -3.2,-0.6 h -1.9 z m 0,-9.1 h 1.7 c 1.8,0 2.8,-0.7 2.8,-2.2 0,-1.5 -0.9,-2.2 -2.8,-2.2 h -1.7 z m -5.9,-8.9 h 9.3 c 1.7,-0.1 3.4,0.4 4.7,1.5 1.1,1.1 1.7,2.7 1.6,4.3 0,1 -0.2,2 -0.6,2.8 -0.4,0.8 -1.1,1.4 -1.9,1.9 0.7,0.1 1.5,0.4 2.1,0.7 0.6,0.3 1,0.7 1.4,1.2 0.4,0.5 0.6,1 0.8,1.6 0.2,0.6 0.2,1.3 0.2,1.9 0,1 -0.2,1.9 -0.6,2.8 -0.4,0.8 -0.9,1.5 -1.6,2.1 -0.7,0.6 -1.5,1 -2.4,1.2 -1.1,0.3 -2.1,0.4 -3.2,0.4 H 51 Z" />
		<path class="letter e" d="M 93,57.2 H 83.1 V 61 h 9.6 v 5 h -9.6 v 3.9 H 93 v 5 H 77.2 V 52.2 H 93 Z" />
		<path class="letter y" d="M 108.2,65.7 100,52.2 h 7 l 4.2,7.3 4.2,-7.3 h 7 L 114,65.7 v 9 h -5.8 z" />
		<path class="letter o" d="m 132.1,63.5 c 0,0.9 0.2,1.7 0.5,2.5 0.6,1.5 1.9,2.7 3.4,3.3 2.4,1 5.1,0.4 6.9,-1.3 0.6,-0.6 1.1,-1.2 1.4,-2 0.7,-1.6 0.7,-3.4 0,-5 -0.3,-0.8 -0.8,-1.4 -1.4,-2 -1.9,-1.7 -4.5,-2.2 -6.9,-1.3 -1.5,0.6 -2.7,1.8 -3.4,3.4 -0.3,0.7 -0.5,1.5 -0.5,2.4 z m -6.1,0 c 0,-1.6 0.3,-3.2 0.9,-4.7 1.2,-2.9 3.6,-5.2 6.5,-6.3 3.2,-1.2 6.8,-1.2 10.1,0 1.5,0.6 2.9,1.4 4,2.5 1.1,1.1 2,2.4 2.5,3.8 1.2,3 1.2,6.4 0,9.4 -0.6,1.4 -1.5,2.7 -2.6,3.8 -1.1,1.1 -2.5,2 -4,2.5 -3.2,1.2 -6.8,1.2 -10.1,0 -1.5,-0.6 -2.8,-1.4 -3.9,-2.5 -1.1,-1.1 -2,-2.4 -2.6,-3.8 -0.5,-1.5 -0.8,-3.1 -0.8,-4.7 z" />
		<path class="letter n" d="M 158.6,74.8 V 52.2 h 5.9 L 175.3,66 V 52.2 h 5.8 v 22.5 h -5.8 L 164.5,61 v 13.8 z" />
		<path class="letter k" d="m 196.7,61.5 7.1,-9.3 h 7.2 l -8.9,10.7 9.8,11.9 h -7.6 L 196.7,65 v 9.8 h -5.9 V 52.2 h 5.9 z" />
  </g>
</svg>

<style>
  svg {
    width: 140px;
  }

  .border,
  .letter {
    fill: var(--byk-c-primary);
  }

	@keyframes kaboom {
		90% {
			transform: scale(1.0);
		}
		100% {
			transform: scale(1.1);
		}
	}
	
	.letter {
		transform-origin: center center;
		animation: kaboom 2s ease alternate infinite;
	}
		
	.b {
		animation-delay: 0.1s;
	}
		
	.e {
		animation-delay: 0.2s;
	}
		
	.y {
		animation-delay: 0.3s;
	}
		
	.o {
		animation-delay: 0.4s;
	}
		
	.n {
		animation-delay: 0.5s;
	}
		
	.k {
		animation-delay: 0.6s;
	}
</style>