<div class="profile-button" class:is-touch={touch}>
  <Button skin={inverted ? 'inverted' : 'secondary'} href={isCustomer ? '/dashboard' : config.dashboardUrl} rel="external" full={touch}>
    <div class="button-content">
      <Avatar
        {config}
        {profile}
      />
      <div class="next-action">
        <p class="display-name">{profile.displayName}</p>
        <p class="action">Go to {isCustomer ? 'My Overview' : 'Dashboard'}</p>
      </div>
    </div>
  </Button>
</div>

<style>
  .profile-button {
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    font-weight: 400;
    font-family: var(--font-base);
  }

  .next-action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .button-content {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0.2rem 1.2rem;
    position: relative;
  }

  .display-name {
    font-weight: 600;
    font-size: 1.8rem;
  }

  .action {
    font-weight: 600;
    font-size: 1.3rem;
  }

  :global(.profile-button.is-touch .c-button),
  :global(.profile-button .c-button) {
    padding: 1rem;
  }
</style>

<script>
  import { Avatar } from '@beyonk/avatar'
  import Button from './Button.svelte'

  export let config
  export let profile
  export let touch = false
  export let inverted

  $: isCustomer = profile.role === 'customer'
</script>