<div class="links--desktop">
  <div>
    <p class="desktop--title">Product</p>
    <FooterLinks links={links.product} />
  </div>
  <div>
    <p class="desktop--title">Solutions</p>
    <FooterLinks links={links.solutions} />
  </div>
  <div>
    <p class="desktop--title">Industries</p>
    <FooterLinks links={links.industries} />
  </div>
  <div>
    <p class="desktop--title">Company</p>
    <FooterLinks links={links.company} />
  </div>
</div>

<script>
  import FooterLinks from './FooterLinks.svelte'

  export let links
</script>

<style>
  .links--desktop {
    display: none;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 800px) {
    .links--desktop {
      display: grid;
    }
  }

  .desktop--title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 1.6;
  }
</style>