<script>
  import { readable } from 'svelte/store'
  import ErrorBoundary from '~components/error-boundary/ErrorBoundary.svelte'

  export let status
  export let error

  const isRecoverable = status === 404
</script>

<svelte:head>
	<title>{status}</title>
</svelte:head>

<div>
  <ErrorBoundary message="Oops! That's a {status} error." error={readable(error)} />

  {#if isRecoverable}
  <a href="/uk/things-to-do" class="button is-primary is-uppercase">Find more things to do</a>
  {/if}
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
  }

  a {
    margin: 12px auto;
  }
</style>