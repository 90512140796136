<script>
  export let error = null
  export let onError = null
  export let message = 'Sorry, we encountered an error'

  const test = process.env.TEST === 'true'

  $: if ($error && onError) onError($error)
</script>

<style lang="scss">
  @import 'src/scss/theme';
  @import 'src/scss/mixins';

  .trace {
    font-family: monospace;
  }

	.error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 0;
  }

  .error.is-test-mode {
    border: 1px solid red;
  }

  img {
    width: 80vw;
  }

  .error p {
    text-align: center;
  }

  .error.is-test-mode p {
    text-align: left;
  }
  
  pre {
    overflow: auto;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 1rem 0;
  }

  @include from ($tablet) {
    img {
      width: 30vw;
    }
    
    p {
      width: 60vw;
    }
  }
</style>

{#if $error}
  <div class="error" class:is-test-mode={test}>
    <img src="/marketplace-static/images/system/warning.svg" alt="Person with error sign" />

    <h1>{message}</h1>
    <p>{test ? $error.message : 'This error has been reported, and our engineering team is on the case! Please retry later.'}</p>

    {#if test && $error.stack}
      <pre class="trace">{$error.stack}</pre>
    {/if}
  </div>
{:else}
  <slot />
{/if}