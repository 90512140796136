<div
  class="accordion"
  class:accordion--open={open}
  class:accordion--branded={branded}
  class:accordion--styled={styled}
  use:clickOutside
  on:outclick={close}
>
  <div
    class="heading"
    bind:this={el}
    on:click={toggle}
    on:keypress={e => e.key === ' ' && toggle()}
  >
    <p>
      {heading}
      <slot name="heading" />
    </p>
    <div>
      {#if open}
        <ChevronUpIcon fillColor="currentColor" />
      {:else}
        <ChevronDownIcon fillColor="currentColor" />
      {/if}
    </div>
  </div>
  <div class="content">
    <slot name="content" />
  </div>
</div>

<script>
  import {
    ChevronDownIcon,
    ChevronUpIcon
  } from '@beyonk/icons'

  let el

  export let open = false
  export let branded = false
  export let styled = true
  export let heading = ''
  export let closeOnClickAway = false
  export let scrollIntoView = false

  export function toggle () {
    open = !open

    if (scrollIntoView) {
      if (open && typeof el?.scrollIntoView === 'function') {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }

  export function close () {
    if (closeOnClickAway) {
      open = false
    }
  }

  export function clickOutside (node) {
    const handleClick = (event) => {
      if (!node.contains(event.target)) {
        node.dispatchEvent(new CustomEvent('outclick'))
      }
    }

    document.addEventListener('click', handleClick, true)

    return {
      destroy () {
        document.removeEventListener('click', handleClick, true)
      }
    }
  }

</script>

<style>
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }

  .heading p {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: var(--heading-font-weight);
    user-select: none;
  }

  .heading p :global(svg) {
    flex-shrink: 0;
  }

  .heading div {
    flex-shrink: 0;
    align-self: flex-start;
  }

  .content {
    display: none;
    margin-top: 8px;
    padding-right: 40px;
  }

  .accordion--styled .content :global(*) {
    color: #fff
  }

  .accordion--styled .content :global(p) {
    line-height: 1.7
  }

  .accordion--styled .content :global(p + p) {
    margin-top: 1rem
  }

  .accordion--branded .heading * {
    color: var(--colour-brand-green);
  }

  .accordion--open .content {
    display: block;
  }
</style>
