<svelte:head>
	<meta property="og:locale" content="en_GB" />
  <meta property="og:image:type" content="image/svg+xml" />
  <meta property="og:site_name" content="Beyonk" />
  <meta property="og:type" content="website" />
  <meta property="og:url" source="layout" content="{openGraphUrl}" />
  <meta property="fb:app_id" content="542198149511985" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:site" content="beyonkgroup" />
  <link rel="canonical" href="{openGraphUrl}" />
  <link rel="icon" type="image/png" href="/marketplace-static/images/favicon/favicon.png" />
  <link rel="preconnect" href="{URLs.api.toString()}" />
  <link rel="stylesheet" type="text/css" href="/marketplace/theme.css?theme={$session.tenant.brand.theme.hex}">
</svelte:head>

{#if process.browser && trackPageViews}
<Analytics
  googleAnalyticsAccounts={gaAccounts}
  metaPixelAccounts={mpAccounts}
/>
{/if}

<HubSpotTracking
  bind:this={hs}
  subdomain="js-eu1"
  hubId={process.env.HUB_SPOT_ID}
  doNotTrack={!trackPageViews}
  loadChatWidgetImmediately={false}
/>

<div class="loader" class:preloading={$preloading}>
  <img src="/marketplace-static/images/system/waiting.svg" alt="Lady sitting on a clock" />
  <p>Things are taking a little longer than usual, please bear with us...</p>
  <AnimatedLogo />
</div>

<div class="surround" class:preloading={$preloading}>
  <NotificationDisplay />

  {#if !$session.tenant.isPortal}
    {#if $session.tenant.isWhitelabel}
    <BrandHeader brand={$session.tenant.brand} />
    {:else}
    <Header
      config={brandConfig}
      {page}
      profile={$session.profile}
      organisation={$session.organisation}
      --colour-semantic-text-heading="hsla(177, 100%, 14%, 1)"
      --colour-semantic-text-body="hsla(0, 0%, 14%, 1)"
      --spacer-unit-3="2.4rem"
      --colour-brand-darker-green="hsla(174, 98%, 20%, 1)"
      --colour-brand-green="hsla(173, 56%, 44%, 1)"
      --shadow-triple-small="0 0 .4rem rgba(35, 35, 35, 0.25), 0 0 .8rem rgba(35, 35, 35, 0.12), 0 0 1.6rem rgba(35, 35, 35, 0.06)"
    />
    {/if}
  {/if}

  <div id="page-content">
    <slot></slot>
  </div>
  {#if $session.tenant.isPortal || $session.tenant.isWhitelabel}
    <div class="attribution-container">
      <Attribution applicationUrl={URLs.homepage.toString()} />
      {#if $session.tenant.requiresGdpr}
        <div class="cookie-consent">
          <button type="button" class="button is-text" on:click={gdprBanner.show}>cookie consent</button>
        </div>
      {/if}
    </div>
  {:else}
    <Footer config={brandConfig} --colour-brand-darkest-green="hsla(177, 100%, 14%, 1)" on:cookie-consent={gdprBanner.show} />
  {/if}
  {#if $session.tenant.requiresGdpr}
    <GdprBanner
      bind:this={gdprBanner}
      cookieName="beyonk_gdpr"
      {cookieConfig}
      choices={cookieChoices}
      heading="Cookie policy"
      showEditIcon={false}
      description={cookieDescription}
      on:analytics={enableAnalytics}
      on:necessary={enableNecessary}
      acceptLabel='Accept'
      settingsLabel='Settings'
    />
  {/if}
</div>

<style lang="scss">
  @import 'src/scss/mixins';

  @keyframes appear {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  .loader {
    background-color: var(--byk-c-white);
    position: fixed;
    display: flex;
    top: -100vh;
    left: -100vw;
    width: 80vw;
    height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid var(--byk-c-grey-light);
    z-index: 9999;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    padding: 20vh 10vw;
    visibility: hidden;

    @include from ($desktop) {
      width: 50vw;
      height: 50vh;
    }

    > img {
      height: 20vh;
    }

    p {
      margin: 2rem 0;
    }

    &.preloading {
      animation: 2s ease 0s normal forwards 1 appear;
      top: 10vh;
      left: 10vw;

      @include from ($desktop) {
        top: 25vh;
        left: 25vw;
      }
    }
  }

  .surround {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .surround :global(div[style*="display: contents"]) {
    display: block !important;
  }

  .surround.preloading {
    transition: 2s filter ease-in;
    filter: grayscale(100%) blur(3px);
  }

  .cookie-consent {
    padding-bottom: 2rem;
  }

  .attribution-container {
    display: flex;
    flex: none;
    justify-content: center;
    width: 100%;
  }
  .attribution-container .button {
    font-size: 12px;
  }
</style>

<script>
  import { onMount, tick } from 'svelte'
  import { BrandHeader } from '~components/headers/index.js'
  import { Header, Footer, Attribution } from '@beyonk/brand'
  import GdprBanner from '@beyonk/gdpr-cookie-consent-banner'
  import { activityIds } from '~util/seo/seo.js'
  import { NotificationDisplay } from '@beyonk/svelte-notifications'
  import { stores } from '@sapper/app'
  import cssVars from 'css-vars-ponyfill'
  import { watchForPageSizeChanges, firePageSizeUpdate } from '~util/resizable-elements/resizable-elements.js'
  import AnimatedLogo from '~components/animated-logo/AnimatedLogo.svelte'
  import { HubSpotTracking } from '@beyonk/svelte-hubspot'
  import { URLs } from '~util/dynamic-environment.js'
  import { Analytics } from '@beyonk/integrations'
  import { ogUrl } from './_og-url.js'

  const { preloading, page, session } = stores()
  const whitelabel = $session.tenant.isWhitelabel && $session.tenant.brand
  const cookieConfig = {
    domain: whitelabel ? whitelabel.hostname : `.${process.env.TLD}`,
    path: '/'
  }

  const brandConfig = {
    app: 'marketplace',
    environmentName: process.env.ENVIRONMENT_NAME,
    cdnBaseUrl: process.env.CDN_BASE_URL,
    dashboardUrl: URLs.dashboard.toString(),
    supportUrl: process.env.SUPPORT_URL,
    authUrl: URLs.auth.toString(),
    applicationUrl: URLs.homepage.toString()
  }

  const openGraphUrl = ogUrl($page, brandConfig.applicationUrl)

  let gdprBanner
  let showFooter = true
  let hs
  let trackPageViews = false
  let gaAccounts = []
  let mpAccounts = []

  $: if (hs && $session.authenticated) {
    hs.setIdentity($session.profile.email)
  }

  onMount(() => {
    cssVars({
      watch: true
    })

    let resizeObserverUnsubscribe
    if ($session.tenant.isPortal || $session.tenant.isWhitelabel) {
      resizeObserverUnsubscribe = watchForPageSizeChanges(window)
    }

    if ($session.via) {
      localStorage.setItem('via', $session.via)
    }

    const pageStoreUnsubscribe = page.subscribe(async ($page) => {
      await tick()
      hs.setPath($page)
      firePageSizeUpdate()
    })

    return () => {
      pageStoreUnsubscribe()
      resizeObserverUnsubscribe && resizeObserverUnsubscribe()
    }
  })

  $: {
    const [ , prefix, _page ] = $page.path.split('/')
    showFooter = prefix !== 'checkout' && !(prefix === 'uk' && activityIds.includes(_page))
  }

  const cookieDescription = 'As with most websites, we use cookies to deliver an optimal experience on Beyonk. By clicking accept you consent to our <a style="color: #32b0a2;" href="/privacy-policy" target="_blank">privacy policy</a>.'
  const cookieChoices = {
    analytics: {
      label: 'Analytics cookies',
      description: 'Used to track user behaviour so that we can deliver the best experience possible.',
      value: true
    },
    tracking: false
  }

  function enableAnalytics () {
    gaAccounts = [
      process.env.GOOGLE_ANALYTICS_PROPERTY_ID,
      process.env.GOOGLE_AD_WORDS_PROPERTY_ID,
      ...whitelabel ? whitelabel.tracking.google : []
    ]

    mpAccounts = [
      process.env.FACEBOOK_PIXEL_ID,
      ...whitelabel ? whitelabel.tracking.facebook : []
    ]
    trackPageViews = true
  }

  function enableNecessary () {
    if (!$session.tenant.isPortal) {
      hs.loadChatWidget()
    }
  }
</script>
