<ul>
  {#each links as link}
    <li>
      <a href={link.url}>{link.name}</a>
    </li>
  {/each}
</ul>

<script>
  export let links
</script>

<style>
  li {
    margin-bottom: 16px;
  }

  @media (min-width: 800px) {
   li {
     margin-bottom: 12px;
    }
  }

  li a {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }
</style>