<div class="doormat">
  <div class="doormat--sign-up doormat--padding">
    <p class="sign-up--title">Your journey to easier and more powerful ticket sales starts here</p>
    <p>Quick sign-up, no obligations</p>
    <a class="sign-up--button" href="/user/signup">Try for free</a>
  </div>

  <div class="doormat--padding">
    <div class="doormat--links">
      <div>
        <MobileMenu
          links={{
            product,
            solutions,
            industries,
            company
            }}
        />
        <DesktopMenu
          links={{
            product,
            solutions,
            industries,
            company
            }}
        />
      </div>
      <div>
        <SocialIcons/>
      </div>
    </div>
    <div class="doormat--terms">
      <div>
        <div class="terms--payment">
          <ul class="associated-providers">
            <li><img src={stripeBadgeImage} width="146" alt="Powered by Stripe" /></li>
            <li><img src={visaImage} width="51" alt="Visa" /></li>
            <li><img src={mastercardImage} width="51" alt="Mastercard" /></li>
            <li><img src={amexImage} width="51" alt="American Express" /></li>
          </ul>
        </div>
        <div class="terms--copyright">
          <p>© Copyright Beyonk Limited</p>
          <p class="terms--divider">Registration Number 11994212 (England and Wales)</p>
        </div>
      </div>

      <div class="terms--links">
        <a href="/terms-and-conditions">Terms and conditions</a>
        <a class="terms--divider" href="/privacy-policy#cookie-policy">Cookie consent</a>
        <a class="terms--divider" href="/privacy-policy">Privacy policy</a>
      </div>
    </div>
  </div>
</div>

<script>
  import MobileMenu from './MobileMenu.svelte'
  import DesktopMenu from './DesktopMenu.svelte'
  import SocialIcons from './SocialIcons.svelte'
  import stripeBadgeImage from './images/stripe-badge-white.png'
  import amexImage from './images/american-express.png'
  import visaImage from './images/visa.png'
  import mastercardImage from './images/mastercard.png'

  const product = [
    { name: 'Why Beyonk', url: '/why-beyonk' },
    { name: 'How it works', url: '/why-beyonk/how-it-works' },
    { name: 'Compare Beyonk', url: '/why-beyonk/compare' },
    { name: 'Integrations', url: '/integrations' },
    { name: 'Pricing', url: '/pricing' },
    { name: 'Book experiences', url: '/uk/things-to-do' },
    { name: 'Partners', url: '/for-partners' }
  ]

  const solutions = [
    { name: 'Free online ticketing', url: '/solutions/free-online-ticketing-system' },
    { name: 'QR scanning', url: '/solutions/qr-scanning-ticketing-system' },
    { name: 'Gift vouchers', url: '/solutions/sell-gift-vouchers-online' },
    { name: 'Capacity management', url: '/solutions/capacity-management-system' },
    { name: 'Zapier', url: '/solutions/zapier-booking-system' },
    { name: 'Product selection', url: '/solutions/product-selection' }
  ]

  const industries = [
    { name: 'Visitor attractions', url: '/industries/attraction-ticketing-software' },
    { name: 'Activities', url: '/industries/activity-booking-system' },
    { name: 'Tours', url: '/industries/tour-booking-system' },
    { name: 'Events', url: '/industries/event-ticketing-system' },
    { name: 'Classes', url: '/industries/classes-booking-system' },
    { name: 'Venue management', url: '/industries/venue-booking-software' },
    { name: 'Leisure', url: '/industries/leisure-booking-system' },
    { name: 'Christmas events', url: '/industries/sell-tickets-for-christmas-events' },
    { name: 'Conferences & B2B', url: '/industries/conferences-b2b-events' }
  ]

  const company = [
    { name: 'About', url: '/why-beyonk/about' },
    { name: 'Contact us', url: '/contact' },
    { name: 'Help Centre', url: 'https://support.beyonk.com/' },
    { name: 'Blog', url: '/blog' },
    { name: 'Climate positive', url: 'https://ecologi.com/beyonk' },
    { name: 'Careers', url: 'https://jobs.beyonk.com/' }
  ]
</script>

<style>
  .doormat {
    background: var(--colour-brand-darkest-green);
    font-size: 1.6rem;
    color: #FFF;
  }

  @media (min-width: 800px) {
    .doormat {
      font-size: 1.8rem;
    }
  }

  .doormat--padding {
    padding: 4.8rem 2.4rem;
  }

  @media (min-width: 800px) {
    .doormat--padding {
      padding: 6.4rem 9rem;
    }
  }

  .doormat--sign-up {
    background: var(--colour-semantic-success-darker, hsla(174, 98%, 20%, 1));
    text-align: center;
    justify-items: center;
    line-height: 1.2;
    display: grid;
    gap: 16px;
  }

  @media (min-width: 800px) {
    .doormat--sign-up {
      gap: 24px;
    }
  }

  .doormat--sign-up .sign-up--title {
    font-weight: 600;
    line-height: 1.5;
    font-size: 20px;
  }

  @media (min-width: 800px) {
    .doormat--sign-up .sign-up--title {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  .doormat--sign-up .sign-up--button {
    padding: 10px;
    width: 99px;
    height: 44px;
    border: 1px solid var(--colour-brand-green, hsla(173, 56%, 44%, 1));
    background: var(--colour-brand-green, hsla(173, 56%, 44%, 1));
    color: #FFF;
    text-decoration: none;
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
  }

  @media (min-width: 800px) {
    .doormat--sign-up .sign-up--button {
      width: 103px;
      height: 48px;
      padding: 12px;
    }
  }

  .doormat--sign-up .sign-up--button:hover {
    background: var(--colour-brand-darkest-green);
    border: 1px solid var(--colour-brand-darkest-green);
    color: #FFF;
  }

  @media (min-width: 800px) {
    .doormat--links {
      display: grid;
      grid-template-columns: 85% 15%;
    }
  }

  @media (min-width: 800px) {
    .doormat--terms {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .terms--links .terms--divider {
      border-left: solid 1px;
      padding-left: 8px;
      margin-left: 8px;
    }

  @media (min-width: 800px) {
    .doormat--terms .terms--divider {
      border-left: solid 1px;
      padding-left: 8px;
      margin-left: 0;
    }
  }

  .doormat--terms .terms--payment {
    margin-bottom: 24px;
  }

  @media (min-width: 800px) {
    .doormat--terms .terms--payment {
      display: flex;
      justify-content: flex-end;
    }
  }

  .doormat--terms .terms--payment .associated-providers {
    display: flex;
    gap: 1.6rem;
    align-items: center;
  }

  .doormat--terms .terms--payment .associated-providers img {
    display: block;
  }

  .doormat--terms .terms--copyright {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8;
    margin-bottom: 44px;
  }

  @media (min-width: 800px) {
    .doormat--terms .terms--copyright {
      font-size: 14px;
      line-height: 1.6;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }

  .doormat--terms .terms--links {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8;
    margin-bottom: 44px;
  }

  @media (min-width: 800px) {
    .doormat--terms .terms--links {
      font-size: 14px;
      line-height: 1.6;
      display: flex;
      gap: 8px;
      order: -1;
      align-items: flex-end;
    }
  }

  .doormat--terms .terms--links a {
    color: #fff;
    text-decoration: none;
  }
</style>