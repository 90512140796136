<ul class="social-icons">
  {#each socialNetworks as socialNetwork}
    <li>
      <a href="{socialNetwork.url}">
        <Icon icon="{socialNetwork.icon}" width="20" height="20" fillColor="#FFF" />
      </a>
    </li>
  {/each}
</ul>
<SocialProof/>

<script>
  import Icon from './SystemIcon.svelte'
  import SocialProof from './SocialProof.svelte'

  const socialNetworks = [
    { icon: 'instagram', name: 'Instagram', url: 'https://www.instagram.com/beyonkgroup/' },
    { icon: 'facebook', name: 'Facebook', url: 'https://www.facebook.com/beyonkgroup/' },
    { icon: 'linkedin', name: 'LinkedIn', url: 'https://www.linkedin.com/company/beyonkgroup/' },
    { icon: 'twitter', name: 'Twitter', url: 'https://twitter.com/beyonkgroup/' }
  ]
</script>

<style>
  .social-icons {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
    max-width: fit-content;
    margin-bottom: 40px;
  }

  @media (min-width: 800px) {
    .social-icons {
      margin: auto 0 48px auto;
    }
  }
</style>
