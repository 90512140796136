<ul class="social-proof">
  <li>
    <img class="social-proof--desktop" src={doormatLogos} alt="Capterra, Software Advice, GetApp" />
    <img class="social-proof--mobile" src={doormatLogosMob} alt="Capterra, Software Advice, GetApp" />
  </li>
</ul>

<script>
  import doormatLogos from './images/doormat-logos.svg'
  import doormatLogosMob from './images/doormat-logos-mob.svg'
</script>

<style>
  ul {
    list-style-type: none;
    padding: 0;
  }

  .social-proof li {
    margin-bottom: 2.4rem;
  }

  .social-proof--desktop {
    display: none;
  }

  .social-proof--mobile {
    display: block;
  }


  @media (min-width: 800px) {
    ul {
      display: grid;
      justify-content: end;
    }

    .social-proof li {
      margin: 0 0 3rem 3rem;
    }

    .social-proof--desktop {
      display: block;
    }

    .social-proof--mobile {
      display: none;
    }
  }
</style>