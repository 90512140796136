<div class="doormat--mobile">
  <Accordion>
    <p slot="heading">Product</p>
    <div slot="content">
      <FooterLinks links={links.product} />
    </div>
  </Accordion>
</div>
<div class="doormat--mobile">
  <Accordion>
    <p slot="heading">Solutions</p>
    <div slot="content">
      <FooterLinks links={links.solutions} />
    </div>
  </Accordion>
</div>
<div class="doormat--mobile">
  <Accordion>
    <p slot="heading">Industries</p>
    <div slot="content">
      <FooterLinks links={links.industries} />
    </div>
  </Accordion>
</div>
<div class="doormat--mobile">
  <Accordion>
    <p slot="heading">Company</p>
    <div slot="content">
      <FooterLinks links={links.company} />
    </div>
  </Accordion>
</div>

<script>
  import Accordion from './Accordion.svelte'
  import FooterLinks from './FooterLinks.svelte'

  export let links
</script>

<style>
  .doormat--mobile {
    margin-bottom: 32px;
  }

  @media (min-width: 800px) {
    .doormat--mobile {
      display: none;
    }
  }

  .doormat--mobile p {
    font-weight: 700;
  }
</style>