<script>
  import Icon from './SystemIcon.svelte'

  export let icon
  export let iconSize = '20'
  export let layout
</script>

{#if layout === 'icon-only'}
  <Icon {icon} width={iconSize} height={iconSize} />
  <span class="u-sr-only"><slot /></span>
{:else if layout === 'icon-right'}
  <span class="c-button__label"><slot /></span>
  <Icon {icon} width={iconSize} height={iconSize} />
{:else if (layout === 'icon-left') || (!layout && icon)}
  <Icon {icon} width={iconSize} height={iconSize} />
  <span class="c-button__label"><slot /></span>
{:else}
  <span class="c-button__label"><slot /></span>
{/if}

<style>
  .u-sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  :global(.c-button--icon svg) {
    position: relative;
    bottom: 0;
    right: .2rem;
  }

  :global(.c-button__label + svg) {
    margin-left: .4rem;
  }

  :global(svg + .c-button__label) {
    margin-left: .4rem;
  }

</style>