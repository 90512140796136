'use strict'

const { ExternalURLs, CookieName, InternalURLs } = require('@beyonk/url-generator')

const URLs = new ExternalURLs(
  process.env.ENVIRONMENT_NAME === 'development',
  process.env.BRANCH_NAME,
  process.env.TLD
)

const internalURLs = new InternalURLs(
  process.env.ENVIRONMENT_NAME === 'development',
  process.env.BRANCH_NAME,
  process.env.INTERNAL_TLD
)

const cookieName = new CookieName(
  process.env.ENVIRONMENT_NAME === 'development' ? 'local' : process.env.ENVIRONMENT_NAME,
  process.env.BRANCH_NAME
)

module.exports = {
  URLs,
  InternalURLs: internalURLs,
  CookieName: cookieName
}
