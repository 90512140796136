<script>
  export let href
  export let rel
  export let pathname
  export let inverted
</script>

<a {href} class="nav-item" class:nav-item--inverted={inverted} class:selected={pathname === href} {rel}><slot /></a>

<style>
  .nav-item {
    color: var(--colour-semantic-text-heading);
    text-decoration: none;
    padding: 0 .4rem;
    font-weight: 500;
    font-size: 1.8rem;
    position: relative;
    opacity: .85;
    display: block;
    height: 7.5rem;
    line-height: 7.5rem;
    white-space: nowrap;
  }

  .nav-item:after {
    transform: scaleX(0);
    content: ' ';
    height: .2rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: .5;
    left: 0;
    background: var(--colour-brand-green);
  }

  .nav-item--inverted {
    color: #fff;
  }

  .nav-item--inverted:after {
    background: #fff;
  }

  .nav-item:hover:after,
  .nav-item:active:after,
  .nav-item:focus:after,
  .nav-item.selected:after {
    transform: scaleX(1);
    transition: transform .5s cubic-bezier(.165,.84,.44,1), opacity .3s cubic-bezier(.165,.84,.44,1);
  }

  .nav-item.selected,
  .nav-item.selected:after {
    opacity: 1;
  }
</style>