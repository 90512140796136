const thisVersion = 1

class RemoteEventDispatcher {
  constructor (w) {
    this.w = w
  }

  dispatch (eventName, meta) {
    const message = JSON.stringify({
      version: thisVersion,
      eventName,
      meta
    })
    this.w.parent.postMessage(message, '*')
  }
}

class RemoteEventObserver {
  constructor (w, originFilter = /.*/) {
    this.w = w
    this.originFilter = originFilter
    this.callbacks = {}
  }

  handle (eventName, ev) {
    if (!ev.origin.match(this.originFilter)) { return }

    try {
      const { version, eventName: givenEventName, meta } = JSON.parse(ev.data)
      if (version && version !== thisVersion) {
        console.debug('Received event from incompatible script version')
      }

      const cb = this.callbacks[eventName]

      if (!cb) { return }

      if (givenEventName === eventName) {
        if ([
          defaultLegacyEventName,
          'beyonk:portal:resize'
        ].includes(eventName)) {
          return cb.callback(meta.height)
        }

        return cb.callback(meta)
      }
    } catch (e) {
      console.debug('Recieved unparseable event', ev.data)
    }
  }

  listen (eventName, callback) {
    const binding = this.handle.bind(this, eventName)
    this.callbacks[eventName] = { callback, binding }
    this.w.addEventListener('message', binding, false)
  }

  ignore (eventName) {
    const cb = this.callbacks[eventName]
    if (!cb) { return }
    this.w.removeEventListener('message', cb.binding, false)
    delete this.callbacks[eventName]
  }
}

const defaultLegacyEventName = 'whitelabel:header:resize'

class LegacyResizableElementObserver extends RemoteEventObserver {
  subscribe (callback, eventName = defaultLegacyEventName) {
    this.eventName = eventName
    const binding = this.handle.bind(this, eventName)
    this.callbacks[eventName] = { callback, binding }
    this.w.addEventListener('message', binding, false)
  }

  unsubscribe () {
    const cb = this.callbacks[this.eventName]
    if (!cb) { return }
    this.w.removeEventListener('message', cb.binding, false)
    delete this.callbacks[this.eventName]
  }
}

class LegacyResizableElementDispatcher extends RemoteEventDispatcher {
  constructor (w, eventName = defaultLegacyEventName) {
    super(w)
    this.eventName = eventName
  }

  update (height) {
    this.dispatch(this.eventName, {
      height
    })
  }
}

export {
  LegacyResizableElementDispatcher as ResizableElementDispatcher,
  LegacyResizableElementObserver as ResizableElementObserver,
  RemoteEventDispatcher,
  RemoteEventObserver
}
