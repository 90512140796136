<script>
  import { setContext } from 'svelte'
  import MobileMenu from './MobileMenu.svelte'
  import Logo from '../Logo.svelte'
  import Button from './Button.svelte'
  import NavItem from './NavItem.svelte'
  import { navState } from './menu-store.js'
  import { brandContextKey } from './context.js'
  import ProfileButton from './ProfileButton.svelte'

  export let profile = false
  export let pathname
  export let config

  setContext(brandContextKey, { config })

  const menuItems = [
    { label: 'Why Beyonk', url: new URL('/why-beyonk', config.applicationUrl).toString() },
    { label: 'Industries', url: new URL('/industries', config.applicationUrl).toString() },
    { label: 'Solutions', url: new URL('/solutions', config.applicationUrl).toString() },
    { label: 'Integrations', url: new URL('/integrations', config.applicationUrl).toString() },
    { label: 'Pricing', url: new URL('/pricing', config.applicationUrl).toString() }
  ]

  const invertedNavPages = [
    /^\/$/,
    /^\/why-beyonk$/,
    /^\/why-beyonk\/compare.*$/,
    /^\/solutions.*$/,
    /^\/pricing.*$/,
    /^\/integrations$/,
    /^\/industries$/
  ]

  function toggleMobileMenu () {
    $navState = !$navState
  }

  $: initialMenuState = pathname ? invertedNavPages.some(regex => pathname.match(regex)) : ''
  let invertedNav = initialMenuState

  let previousY
  let currentY

  const deriveDirection = (y) => {
    const direction = !previousY || previousY < y ? 'down' : 'up'
    previousY = y
    return direction
  }

  $: scrollDirection = deriveDirection(currentY)
  $: offscreen = scrollDirection === 'down' && currentY > 0

  $: if (scrollDirection === 'up' && currentY !== 0) {
    invertedNav = false
  } else {
    invertedNav = initialMenuState
  }
</script>

<svelte:window bind:scrollY={currentY}/>
{#if $navState}
  <MobileMenu {menuItems} {profile} on:logout />
{:else}
  <header
    class="header"
    class:header--inverted={invertedNav}
    class:shadow={!invertedNav && currentY > 0}
    class:hide-navigation={offscreen}
  >
    <div class="header-inner">
      <div class="header-left">

        <div class="brand">
          <Logo inverse={invertedNav} />
        </div>

        <nav class="wide-nav">
          {#each menuItems as menuItem}
            <NavItem inverted={invertedNav} {pathname} href={menuItem.url}>{menuItem.label}</NavItem>
          {/each}
        </nav>

      </div>

      <div class="controls">
        <div class="header-right">
          {#if profile}
            <div class="header-authenticated">
              <ProfileButton {config} {profile} inverted={invertedNav}/>
            </div>
          {:else}
            <div class="extended-controls">
              <NavItem
                href={new URL('/login', config.authUrl).toString()}
                inverted={invertedNav}
                {pathname}
              >
                Log in
              </NavItem>
              <Button
                skin={invertedNav ? 'inverted' : 'secondary'}
                href="https://beyonk.is/available"
              >
                Book a demo
              </Button>
            </div>
          {/if}
        </div>
        {#if !profile}
        <Button
          href={new URL('/signup', config.authUrl).toString()}
          skin='tertiary'
        >
          Try for free
        </Button>
        {/if} 
        <div class="menu-mobile">
          <ul class="menu-mobile-items">
            <li>
              <Button layout="icon-only" skin={invertedNav ? 'borderless-i' : 'borderless'} on:click={toggleMobileMenu} iconSize="24" icon="menu">Menu</Button>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </header>
{/if}

<style>
  :root {
    --app-header-height: 6rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .header {
    height: var(--app-header-height);
  }

  @media (min-width: 1000px) {
    :root {
      --app-header-height: 7.5rem;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;

    background: #FFF;

    width: 100%;
    z-index: 3000;
    transition: box-shadow 0.18s ease-in-out;
  }

  .header.shadow {
    box-shadow: var(--shadow-triple-small);
    transition: box-shadow 0.18s ease-in-out;
  }

  .header--inverted {
    background: transparent;
  }

  :global(.c-site-wrapper.header-is-fixed .header) {
    position: fixed;
    top: 0;
    opacity: 1;
    transition: opacity 0.3s
  }

  .hide-navigation {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s
  }

  @media (min-width: 1000px) {
    .menu-mobile {
      display: none;
    }
  }

  .menu-mobile {
    margin-right: -1.4rem;
  }

  .menu-mobile-items {
    display: flex;
  }

  .header-inner {
    height: 100%;
    max-width: 144rem;
    margin: 0 auto;
    padding: 0 var(--spacer-unit-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-inner .wide-nav,
  .header-inner .extended-controls,
  .header-inner .header-right {
    display: none;
  }

  @media (min-width: 1000px) {
    .header-inner .wide-nav {
      display: flex;
    }

    .header-inner .extended-controls {
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }

    .header-inner .header-right {
      display: flex;
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.wide-nav a) {
    margin-right: 1.6rem;
  }

  .header-right {
    align-items: center;
  }

  .brand {
    margin-right: 4rem;
  }

  :global(.brand img) {
    width: 11.6rem;
  }

  @media (min-width: 1000px) {
    :global(.brand img) {
      width: 13.6rem;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
</style>
